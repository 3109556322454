export const state = () => ({
  impersonator: null
})

export const getters = {
  isImpersonating(state) {
    return !!state.impersonator
  }
}

export const mutations = {
  setImpersonator(state, impersonator) {
    state.impersonator = impersonator
  }
}

export const actions = {
  async impersonate({ commit, state }, customerId) {
    await this.$axios.post('auth/impersonate', {
      user_id: customerId
    })
    await this.$auth.fetchUser()
  },
  async leave({ commit, state }) {
    await this.$axios.delete('auth/impersonate')
    await this.$auth.fetchUser()
  }
}
