<template>
  <div class="flex items-center flex-col min-h-screen bg-gray-800">
    <div class="m-16">
      <img src="~/assets/logo.png" alt="Indie Film Lab" class="h-16 block" />
    </div>
    <nuxt class="flex-1 container mx-auto text-gray-300" />
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: 'Indie Film Lab'
    }
  }
}
</script>
