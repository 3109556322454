const middleware = {}

middleware['employee'] = require('../middleware/employee.js')
middleware['employee'] = middleware['employee'].default || middleware['employee']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['load-products'] = require('../middleware/load-products.js')
middleware['load-products'] = middleware['load-products'].default || middleware['load-products']

export default middleware
