import Vue from 'vue'
const uuidv4 = require('uuid/v4')

export const state = () => ({
  items: [],
  rush: false,
  contactSheets: false,
  returnNegatives: 0,
  adjustment: 0,
  coupon: null,
  removed: []
})

export const mutations = {
  reset(state) {
    state.items = []
    state.rush = false
    state.contactSheets = false
    state.returnNegatives = 0
    state.adjustment = 0
    state.coupon = null
    state.removed = []
  },
  add(state, { product, options, notes, price, quantity }) {
    state.items.push({
      id: uuidv4(),
      product: product,
      options: options,
      notes: notes,
      price: price,
      quantity: quantity
    })
  },
  update(state, item) {
    const index = state.items.indexOf(
      state.items.find((existingItem) => {
        return existingItem.id === item.id
      })
    )
    state.items[index] = item
  },
  remove(state, id) {
    const removed = state.items.splice(
      state.items.indexOf(
        state.items.find((item) => {
          return item.id === id
        })
      ),
      1
    )
    state.removed.push(removed[0])
    let processAndScanCount = 0
    state.items.forEach((item) => {
      if (item.product === 'process-and-scan') {
        processAndScanCount += item.quantity
      }
    })
    if (processAndScanCount === 0) {
      state.contactSheets = false
    }
    Vue.notify({
      title: 'Removed Item',
      text: 'Item has been removed from your cart.'
    })
  },
  removeAll(state) {
    state.removed = state.removed.concat(state.items)
    state.items = []
    Vue.notify({
      title: 'Cart Cleared',
      text: 'All items have been removed from your cart.'
    })
  },
  restore(state, id) {
    const restored = state.removed.splice(
      state.removed.indexOf(
        state.removed.find((item) => {
          return item.id === id
        })
      ),
      1
    )
    state.items.push(restored[0])
    Vue.notify({
      title: 'Item Restored',
      text: "We've restored the item to your cart."
    })
  },
  updateQuantity(state, { id, quantity }) {
    state.items.find((item) => {
      return item.id === id
    }).quantity = quantity
  },
  setRush(state, isRush) {
    state.rush = isRush
  },
  setContactSheets(state, contactSheets) {
    let processAndScanCount = 0
    state.items.forEach((item) => {
      if (item.product === 'process-and-scan') {
        processAndScanCount += item.quantity
      }
    })
    if (processAndScanCount === 0) {
      Vue.notify({
        type: 'error',
        title: 'Option not available',
        text: 'Contact sheets are only available for process & scan rolls'
      })
      return
    }
    state.contactSheets = contactSheets
  },
  setReturn(state, isReturn) {
    state.returnNegatives = isReturn
  },
  setDiscount(state, amount) {
    state.adjustment = -amount
  },
  setCoupon(state, coupon) {
    state.coupon = coupon
  },
  removeCoupon(state) {
    state.coupon = null
  }
}

export const getters = {
  groupedItems: (state) => {
    return state.items.reduce((r, a) => {
      r[a.product] = [...(r[a.product] || []), a]
      return r
    }, {})
  },
  rollCount: (state) => {
    let count = 0
    state.items.forEach((item) => {
      count += item.quantity
    })
    return count
  },
  eligibleContactSheetCount: (state) => {
    let count = 0
    state.items.forEach((item) => {
      if (item.product === 'process-and-scan') {
        count += item.quantity
      }
    })
    return count
  },
  itemsTotal: (state) => {
    let total = 0
    state.items.forEach((item) => {
      total += item.price * item.quantity
    })
    return total
  },
  couponDiscount: (state, getters) => {
    if (state.coupon) {
      return -Math.round(
        getters.itemsTotal * (state.coupon.percentageDiscount / 100)
      )
    }
    return 0
  },
  rushFee: (state, getters) => {
    if (state.rush) {
      return Math.round(getters.itemsTotal * process.env.rushFeePercentage)
    }
    return 0
  },
  contactSheetsFee: (state, getters) => {
    if (state.contactSheets) {
      return getters.eligibleContactSheetCount * 400
    }
    return 0
  },
  returnFee: (state, getters) => {
    let fee = 0
    if (state.returnNegatives === 0) {
      fee += 900
      if (getters.rollCount > 10) {
        fee += 300
      }
    }
    return fee
  },
  priceComponents: (state, getters, rootState) => {
    const components = [{ name: 'Items', amount: getters.itemsTotal }]
    if (getters.couponDiscount !== 0) {
      components.push({
        name: `${state.coupon.code} (${state.coupon.percentageDiscount}% off)`,
        amount: getters.couponDiscount
      })
    }
    if (getters.rushFee !== 0) {
      components.push({ name: 'Rush', amount: getters.rushFee })
    }
    if (getters.contactSheetsFee !== 0) {
      components.push({
        name: `Digital Contact Sheets (${getters.eligibleContactSheetCount} ${
          getters.eligibleContactSheetCount > 1 ? 'rolls' : 'roll'
        })`,
        amount: getters.contactSheetsFee
      })
    }
    if (rootState.auth.loggedIn) {
      const userDiscount = rootState.auth.user.percentage_discount
      if (userDiscount !== 0) {
        components.push({
          name: `${userDiscount}% Discount`,
          amount: -Math.round(
            (getters.itemsTotal + getters.rushFee) * (userDiscount / 100)
          )
        })
      }
    }
    if (getters.returnFee !== 0) {
      components.push({ name: 'Sleeve & Return', amount: getters.returnFee })
    }
    if (state.adjustment !== 0) {
      components.push({ name: 'Discount', amount: state.adjustment })
    }
    return components
  },
  totalPrice: (state, getters) => {
    let total = 0
    getters.priceComponents.forEach((component) => {
      total += component.amount
    })
    return total
  },
  amountDue: (state, getters) => {
    return Math.max(0, getters.totalPrice - getters.creditBalance)
  },
  creditBalance: (state, getters, rootState) => {
    if (!rootState.auth.loggedIn) {
      return 0
    }
    return rootState.auth.user.credit_balance
  },
  balanceApplied: (state, getters) => {
    if (getters.amountDue > 0) {
      return getters.creditBalance
    }
    return getters.totalPrice
  },
  couponCode: (state) => {
    if (state.coupon === null) {
      return null
    }
    return state.coupon.code
  },
  isEmpty: (state, getters) => {
    return getters.rollCount === 0
  }
}

export const actions = {
  applyCoupon({ commit }, couponCode) {
    return this.$axios
      .post('api/v1/cart/coupon', {
        code: couponCode
      })
      .then((response) => {
        commit('setCoupon', {
          code: couponCode.toUpperCase(),
          percentageDiscount: response.data.discount
        })
      })
  }
}
