<template>
  <div class="bg-gray-700">
    <div class="container px-8 m-auto">
      <div
        class="text-gray-700 flex flex-col md:flex-row justify-between items-center size-6"
      >
        <div class="flex items-center w-full md:w-auto justify-between">
          <img
            src="~/assets/logo.png"
            alt="Indie Film Lab"
            class="my-4 md:m-0 h-8 block"
          />
          <span
            @click="showMenu = !showMenu"
            class="ml-8 md:hidden cursor-pointer"
          >
            <span class="block h-1 w-6 mb-1 bg-gray-800 rounded" />
            <span class="block h-1 w-6 mb-1 bg-gray-800 rounded" />
            <span class="block h-1 w-6 bg-gray-800 rounded" />
          </span>
        </div>
        <div
          class="md:flex flex-col md:flex-row w-full md:w-auto text-center"
          :class="{
            hidden: !showMenu,
            flex: showMenu
          }"
        >
          <a
            href="https://www.indiefilmlab.com/faq"
            class="px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
            target="_blank"
          >
            FAQ
          </a>
          <nuxt-link
            to="/gift-cards/"
            class="px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
          >
            Gift Cards
          </nuxt-link>
          <nuxt-link
            v-if="!$auth.loggedIn"
            to="/login"
            class="px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
          >
            Log In
          </nuxt-link>
          <nuxt-link
            v-else
            to="/orders"
            class="px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
          >
            My Orders
          </nuxt-link>
          <nuxt-link
            v-if="rollCount > 0"
            to="/cart"
            class="px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
          >
            Cart
            <span
              class="rounded-full text-sm px-2 inline-block bg-gray-800"
              v-text="rollCount"
            />
          </nuxt-link>
          <nuxt-link
            v-else
            to="/cart"
            class="px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
          >
            Start an Order
          </nuxt-link>
          <div class="group relative" v-if="$auth.loggedIn">
            <div
              class="flex items-center justify-center px-4 py-4 text-lg text-gray-500 hover:text-white border-gray-700 border-b-4 hover:border-orange-400"
            >
              <span v-text="$auth.user.name" />
              <span class="caret ml-2" />
            </div>
            <div
              class="hidden group-hover:flex flex-col absolute rounded-b overflow-hidden bg-gray-700 right-0 shadow-lg z-10"
            >
              <nuxt-link
                v-if="isEmployee && !isImpersonating"
                to="/impersonate"
                class="px-4 py-4 text-lg text-gray-500 hover:text-white hover:bg-gray-800 border-gray-800 border-t"
              >
                Impersonate
              </nuxt-link>
              <nuxt-link
                to="/profile"
                class="px-4 py-4 text-lg text-gray-500 hover:text-white hover:bg-gray-800 border-gray-800 border-t"
              >
                Edit Profile
              </nuxt-link>
              <button
                v-if="$auth.loggedIn"
                class="px-4 py-4 text-lg text-gray-500 hover:text-white hover:bg-gray-800 border-gray-800 border-t"
                @click="$auth.logout()"
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isImpersonating" class="bg-red-800">
      <div class="container px-8 py-6 m-auto text-center text-white">
        You (<strong v-text="$store.state.impersonation.impersonator.name" />)
        are impersonating a customer (<strong v-text="$auth.user.name" />)
        <button
          class="rounded text-red-900 bg-red-400 ml-1 px-2 py-1 border-b-2 border-red-500 hover:bg-red-500 hover:border-red-600 hover:shadow-lg"
          @click="leaveImpersonation"
        >
          Cancel
        </button>
      </div>
    </div>
    <div
      v-if="$auth.loggedIn && !$auth.user.email_verified"
      class="bg-gray-800"
    >
      <div class="container px-8 py-3 m-auto text-center text-white">
        Please check your email for a verification link to ensure scans are
        delivered to the correct address.
        <button
          v-if="!verificationResent"
          @click="resendVerification"
          class="rounded bg-orange-400 ml-1 px-2 py-1 border-b-2 border-orange-500 hover:bg-orange-500 hover:border-orange-600 hover:shadow-lg"
        >
          Resend Verification
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showMenu: false,
      verificationResent: false
    }
  },
  computed: {
    ...mapGetters('cart', ['rollCount']),
    ...mapGetters('impersonation', ['isImpersonating']),
    isEmployee() {
      if (!this.$auth.loggedIn) return false
      return !!this.$auth.user.roles.find((role) => {
        return role.name === 'employee'
      })
    }
  },
  methods: {
    async leaveImpersonation() {
      await this.$store.dispatch('impersonation/leave')
      this.$notify({
        title: 'Impersonation Cancelled',
        text: 'You are no longer impersonating a customer.'
      })
    },
    resendVerification() {
      this.verificationResent = true
      this.$axios
        .post('/api/v1/account/resend-verification')
        .then((res) => {
          this.$notify({
            title: 'Verification Sent',
            text: 'Please check your email for a link to verify your address.'
          })
        })
        .catch((e) => {
          this.$notify({
            type: 'error',
            title: 'Problem Verifying',
            text: 'There was a problem sending your verification email.'
          })
        })
    }
  }
}
</script>

<style>
.caret {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
}
</style>
