export default ({ $axios, store, app, redirect }) => {
  $axios.onError((error) => {
    if (error.response.status === 401) {
      app.$auth.setUser(false)
      redirect('/login')
    }
  })
  $axios.onResponse((response) => {
    if (response.config.url.includes('/api/v1/auth/user')) {
      store.commit('impersonation/setImpersonator', response.data.impersonator)
    }
    if (response.config.url.includes('/auth/logout')) {
      store.commit('impersonation/setImpersonator', null)
    }
  })
}
