<template>
  <div class="container text-center">
    <template v-if="error.statusCode !== 503">
      <div class="m-8">
        <h1 class="text-6xl" v-text="error.statusCode" />
        <h2 class="text-xl text-gray-500" v-text="message" />
      </div>
      <div class="m-8">
        <nuxt-link to="/">
          Home
        </nuxt-link>
      </div>
    </template>
    <div v-else class="flex justify-center">
      <div class="mx-4 my-8 md:w-3/5 w-full">
        <h1 class="font-bold text-5xl mb-4 flex justify-around">
          <span class="block border-b-8 border-gray-900">
            <span class="block -mb-8">Be right back!</span>
          </span>
        </h1>
        <p class="mt-8 text-center">
          Our site is currently undergoing maintenance, please check back later.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'minimal',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    message() {
      if (this.error.message) {
        return this.error.message
      }
      return 'An error occurred'
    }
  }
}
</script>
