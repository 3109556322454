export const state = () => ({
  all: [],
  loaded: false
})

export const mutations = {
  setAll(state, products) {
    state.all = products
  },
  markLoaded(state) {
    state.loaded = true
  }
}

export const actions = {
  async loadAll({ commit }) {
    commit('markLoaded')
    const response = await this.$axios.get('api/v1/products')
    commit('setAll', response.data.data)
  }
}

export const getters = {
  // eslint-disable-next-line
  getProductByKey: (state) => (key) => {
    return state.all.find((product) => {
      return product.key === key
    })
  }
}
